import React, { useState } from "react";
import { Box, Button } from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import InfoIcon from "@mui/icons-material/Info";
import BuildIcon from "@mui/icons-material/Build";
import ContactMailIcon from "@mui/icons-material/ContactMail";
import DashboardIcon from "@mui/icons-material/Dashboard";
import { useNavigate } from "react-router-dom";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import AutoStoriesIcon from "@mui/icons-material/AutoStories";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import SettingsIcon from "@mui/icons-material/Settings";

import {
  signInWithRedirect,
  signOut,
  getCurrentUser,
  fetchAuthSession,
  AuthUser,
  Auth,
} from "aws-amplify/auth";
const styles = {
  navBar: {
    display: "flex",
    flexDirection: "column",
    gap: 4,
  },
  button: {
    color: "white",
    flexDirection: "column",
    padding: 2,
    fontSize: 12,
    alignItems: "center",
  },
  icon: {
    transform: "scale(2)",
    width: 32,
    marginLeft: 1,
    marginBottom: 2,
  },
};

function NavBar() {
  const navigate = useNavigate();

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 4 }}>
      <Button
        startIcon={<HomeIcon sx={styles.icon} />}
        onClick={(e) => {
          navigate("/Overview");
        }}
        sx={styles.button}
      >
        My Week
      </Button>
      <Button
        startIcon={<AutoStoriesIcon sx={styles.icon} />}
        sx={styles.button}
        onClick={(e) => {
          navigate("/Courses");
        }}
      >
        Courses
      </Button>
      <Button
        startIcon={<CalendarMonthIcon sx={styles.icon} />}
        onClick={(e) => {
          navigate("/Calendar");
        }}
        sx={styles.button}
      >
        Calendar
      </Button>
      <Button
        startIcon={<AccountCircleIcon sx={styles.icon} />}
        href="/Contact"
        sx={styles.button}
      >
        Profile
      </Button>
      <Button
        startIcon={<SettingsIcon sx={styles.icon} />}
        sx={styles.button}
        onClick={async () => {
          await signOut();
          window.location = "/";
        }}
      >
        Logout
      </Button>
    </Box>
  );
}

export default NavBar;

import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  TextField,
  Typography,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import OpenPlanner from "./OpenPlannerAPI";
import convertToDateTimeLocal from "./convertToDateTimeLocal";
import { AddToCalendarButton } from "add-to-calendar-button-react";
import DeleteEventButton from "./DeleteEventButton";
import WYSIWYG, {
  Editor,
  EditorProvider,
  Toolbar,
  BtnBold,
} from "react-simple-wysiwyg";
import EditNotifications from "./EditNotifications";

function ShareEvent({ event }) {
  return (
    <add-to-calendar-button
      name={event.body}
      startDate={event.start}
      startTime={event.start}
      endTime={event.end}
      endDate={event.end}
      timeZone="America/Los_Angeles"
      description={event.title}
      options="'Apple','Google','Outlook.com'"
      buttonsList
      hideTextLabelButton
      buttonStyle="round"
      lightMode="bodyScheme"
    ></add-to-calendar-button>
  );
}

export default function EditEvent({ event, onSubmit }) {
  const [formData, setFormData] = useState({
    id: event.id,
    start: convertToDateTimeLocal(event.start),
    end: convertToDateTimeLocal(event.end),
    title: event.title,
    body: event.body,
    calendar: event.calendar,
    isCompleted: event.isCompleted,
  });

  const [notificationsValues, setNotificationsValues] = useState({});

  if (!event) return null;
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const UpdateNotifications = async () => {
    for (const notificationID of Object.keys(notificationsValues)) {
      let unit = notificationsValues[notificationID][0];
      let value = notificationsValues[notificationID][1];
      if (notificationID == "null") {
        await OpenPlanner.addNotification(event.id, value, unit);
      } else {
        await OpenPlanner.updateNotification(notificationID, value, unit);
      }
    }
  };
  const handleStartDate = (e) => {
    const { name, value } = e.target;
    // If the end date is before the start date, set the end date to the start date plus 30 min
    let newEnd = formData.end;
    if (new Date(formData.end) < new Date(value)) {
      newEnd = convertToDateTimeLocal(
        new Date(new Date(value).getTime() + 30 * 60000)
      );
    }
    setFormData((prevData) => ({
      ...prevData,
      start: value,
      end: newEnd,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Reset form
    await OpenPlanner.updateEvent({
      ...formData,
      start: new Date(formData.start).toISOString(),
      end: new Date(formData.end).toISOString(),
    });
    await UpdateNotifications();
    setFormData({
      id: "",
      start: "",
      end: "",
      title: "",
      body: "",
      calendar: "",
      isCompleted: false,
    });
    onSubmit();
  };

  const handleTitle = (e) => {
    setFormData((prevData) => ({
      ...prevData,
      title: e.target.value,
    }));
  };
  const handleBody = (e) => {
    setFormData((prevData) => ({
      ...prevData,
      body: e.target.value,
    }));
  };

  return (
    <Box sx={{ padding: 3, maxWidth: 600, margin: "0 auto" }}>
      <Typography variant="h4" gutterBottom>
        Edit Event
      </Typography>
      <form onSubmit={handleSubmit}>
        <TextField
          label="Start"
          name="start"
          type="datetime-local"
          value={formData.start}
          onChange={handleStartDate}
          fullWidth
          margin="normal"
          required
        />
        <TextField
          label="End"
          name="end"
          type="datetime-local"
          value={formData.end}
          onChange={handleChange}
          fullWidth
          margin="normal"
          required
        />
        Title
        <EditorProvider>
          <Editor value={formData.body} onChange={handleBody}></Editor>
        </EditorProvider>
        Optional Notes
        <EditorProvider>
          <Editor
            containerProps={{
              style: {
                minHeight: "20px",
              },
            }}
            value={formData.title}
            onChange={handleTitle}
          ></Editor>
        </EditorProvider>
        <EditNotifications
          onChange={setNotificationsValues}
          eventID={event.id}
        />
        <Box sx={{ display: "flex", alignItems: "center", mt: 2 }}>
          <Button type="submit" variant="contained" color="primary">
            Save
          </Button>
          &nbsp;
          <DeleteEventButton onDelete={onSubmit} id={event.id} />
          <Button onClick={onSubmit}>Cancel</Button>
          <div style={{ marginRight: 100 }}></div>
          <ShareEvent event={formData} />
        </Box>
      </form>
    </Box>
  );
}

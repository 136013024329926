import React, { useEffect } from "react";
import { useParams } from "react-router";
import { Box, Typography, Button, Dialog } from "@mui/material";
import EventsList from "../components/Overview/EventsList.js";
import OpenPlannerAPI from "../components/common/OpenPlannerAPI.js";
import CourseLegend from "../components/Overview/CourseLegend.js";
import sortEventsByDay from "../components/common/sortEventsByDay.js";
import AddEvent from "../components/common/AddEvent.js";
import DeleteCourseButton from "../components/common/DeleteCourseButton.js";
const formatDueDate = (dateString) => {
  const options = { weekday: "long", month: "long", day: "numeric" };
  const date = new Date(dateString);
  return new Intl.DateTimeFormat("en-US", options).format(date);
};

export default function Course() {
  const [events, setEvents] = React.useState([]);
  const [courses, setCourses] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [showAllPastEvents, setShowAllPastEvents] = React.useState(false);
  const [addEventForm, setAddEventForm] = React.useState(false);
  const { id } = useParams();

  useEffect(() => {
    const fetchEvents = async () => {
      let data = await OpenPlannerAPI.getCourseEvents(id);
      const today = new Date();

      data.sort((a, b) => {
        const dateA = new Date(a.start);
        const dateB = new Date(b.start);
        return Math.abs(dateA - today) - Math.abs(dateB - today);
      });
      if (data) setEvents(data);
    };
    fetchEvents();

    const fetchCourses = async () => {
      let data = await OpenPlannerAPI.getCourses();
      if (data) setCourses(data);
      setLoading(false);
    };
    fetchCourses();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  const eventsByDay = sortEventsByDay(events);
  let pastEvents = events.filter((event) => new Date(event.start) < new Date());
  if (!showAllPastEvents) {
    pastEvents = pastEvents.slice(0, 3);
  }
  return (
    <Box sx={{ padding: 3 }}>
      <Typography variant="h4" gutterBottom>
        {courses.find((course) => course.id === id)?.name}
      </Typography>
      <EventsList
        events={
          eventsByDay.length &&
          new Date(eventsByDay[0][0].start).toDateString() ===
            new Date().toDateString()
            ? eventsByDay[0]
            : []
        }
        title="Due Today"
        courses={courses}
      />
      {eventsByDay.map((events, index) => {
        if (
          new Date(events[0].start).toDateString() === new Date().toDateString()
        )
          return null;
        return (
          <EventsList
            events={events}
            courses={courses}
            title={`Due on ` + formatDueDate(events[0].start)}
          />
        );
      })}

      <EventsList events={pastEvents} title="Past Events" courses={courses} />
      <Button
        onClick={() => {
          setShowAllPastEvents(!showAllPastEvents);
        }}
      >
        {showAllPastEvents ? "Show less events" : "Show all events"}
      </Button>
      <Dialog open={addEventForm} onClose={() => setAddEventForm(false)}>
        <AddEvent
          calendar={id}
          onSubmit={() => {
            window.location.reload();
            setAddEventForm(false);
          }}
        />
      </Dialog>
      <Button
        onClick={() => {
          setAddEventForm(!addEventForm);
        }}
      >
        Add Event
      </Button>
      <DeleteCourseButton id={id} />
    </Box>
  );
}

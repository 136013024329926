import React, { useEffect } from "react";
import { Box, Typography } from "@mui/material";

export default function CourseLegend({ courses }) {
  return (
    <Box
      style={{ marginBottom: 20 }}
      display="flex"
      flexDirection="row"
      flexWrap="wrap"
      gap={2}
    >
      {courses.map((course) => (
        <Box key={course.id} display="flex" alignItems="center">
          <Box
            sx={{
              width: 16,
              height: 16,
              backgroundColor: course.color,
              marginRight: 1,
            }}
          />
          <Typography style={{ marginTop: 5 }} variant="body2" gutterBottom>
            {course.name}
          </Typography>
        </Box>
      ))}
    </Box>
  );
}

import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import React, { useEffect } from "react";
import Login from "./pages/Login.js";
import "./components/common/auth.js";
import Courses from "./pages/Courses.js";
import Overview from "./pages/Overview.js";
import NavBar from "./components/common/NavBar.js";
import OpenPlannerAPI from "./components/common/OpenPlannerAPI.js";
import Container from "./components/common/Container.js";
import Course from "./pages/Course.js";
import Calendar from "./pages/Calendar.js";
import Contact from "./pages/Contact.js";
import { isLoggedIn } from "./components/common/auth.js";
import Home from "./pages/Home.js";
function App() {
  return (
    <div>
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/login" element={<Login />} />
          <Route
            path="/Overview"
            element={
              <Container>
                <Overview />
              </Container>
            }
          />
          <Route
            path="/Courses"
            element={
              <Container>
                <Courses />
              </Container>
            }
          />
          <Route
            path="/Course/:id"
            element={
              <Container>
                <Course />
              </Container>
            }
          />
          <Route
            path="/Calendar"
            element={
              <Container>
                <Calendar />
              </Container>
            }
          />
          <Route
            path="/Contact"
            element={
              <Container>
                <Contact />
              </Container>
            }
          />
        </Routes>
      </Router>
    </div>
  );
}

export default App;

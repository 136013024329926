/*
Return dates in sorted order
*/
export default function sortEventsByDay(events) {
  let sortedEvents = events.toSorted((a, b) => {
    return new Date(a.start) - new Date(b.start);
  });
  let sortedDays = [];
  let index = 0;
  while (index < sortedEvents.length) {
    let currentDay = [];
    let day = new Date(sortedEvents[index].start).toDateString();
    while (
      index < sortedEvents.length &&
      day === new Date(sortedEvents[index].start).toDateString()
    ) {
      currentDay.push(sortedEvents[index]);
      index++;
    }
    sortedDays.push(currentDay);
  }
  return sortedDays;
}

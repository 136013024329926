import { Box, Typography } from "@mui/material";
import { FileUploader } from "react-drag-drop-files";
import UploadFileIcon from "@mui/icons-material/UploadFile";

export default function UploadCard({ handleChange }) {
  const fileTypes = ["PDF"];
  return (
    <Box margin={"20px"}>
      <FileUploader handleChange={handleChange} name="file" types={fileTypes}>
        <Box
          sx={{
            border: 1,
            height: "300px",
            width: 345,
            borderRadius: 1,
            borderStyle: "dashed",
            borderColor: "grey.500",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "background.paper",
            cursor: "pointer",
            transition: "background-color 0.3s, border-color 0.3s",
            "&:hover": {
              backgroundColor: "grey.100",
              borderColor: "grey.700",
            },
            padding: 2,
          }}
        >
          <UploadFileIcon sx={{ fontSize: 48, color: "grey.700", mb: 1 }} />
          <Typography variant="h6" color="textSecondary">
            Upload Syllabus Here
          </Typography>
          <Typography variant="body2" color="textSecondary" sx={{ mt: 1 }}>
            Accepted file types: .pdf
          </Typography>
        </Box>
      </FileUploader>
    </Box>
  );
}

import { Amplify } from "aws-amplify";
import {
  signInWithRedirect,
  signOut,
  getCurrentUser,
  fetchAuthSession,
  AuthUser,
  Auth,
} from "aws-amplify/auth";

Amplify.configure({
  Auth: {
    Cognito: {
      userPoolClientId: "6rgrhfqkq4c2fmget9vltekreb",
      userPoolId: "us-east-1_OSeuvUQEK",
      loginWith: {
        oauth: {
          domain: "login.open-planner.com",
          scopes: ["openid", "email", "phone", "aws.cognito.signin.user.admin"],
          redirectSignIn: [
            "http://localhost",
            "https://open-planner.com",
            "https://www.open-planner.com",
          ],
          redirectSignOut: [
            "http://localhost",
            "https://open-planner.com",
            "https://www.open-planner.com",
          ],
          responseType: "code",
        },
        username: "true",
      },
    },
  },
});

export async function getToken() {
  try {
    let session = await fetchAuthSession();
    return session.tokens.accessToken;
  } catch (e) {
    console.warn(e);
  }
}

export async function isLoggedIn() {
  try {
    await getCurrentUser();
  } catch (e) {
    console.log(e);
    return false;
  }
  return true;
}

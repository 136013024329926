import React, { useState } from "react";
import { Box, Button, TextField, Typography } from "@mui/material";

export default function Contact() {
  return (
    <Box>
      <iframe
        src="https://docs.google.com/forms/d/e/1FAIpQLSe7d45wjI6m_JRF-qEPpJ8e7bhn5OQSAYgefB760L1s9e1Z8A/viewform?embedded=true"
        width="640"
        height="850px"
        frameborder="0"
        marginheight="0"
        marginwidth="0"
      >
        Loading…
      </iframe>
    </Box>
  );
}

import WYSIWYG, {
  Editor,
  EditorProvider,
  Toolbar,
  BtnBold,
} from "react-simple-wysiwyg";
import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  TextField,
  Typography,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";
import OpenPlanner from "./OpenPlannerAPI";
import convertToDateTimeLocal from "./convertToDateTimeLocal";

export default function AddEvent({ calendar, onSubmit, event, courses }) {
  const [formData, setFormData] = useState({
    start: event?.start ? convertToDateTimeLocal(event.start) : "",
    end: event?.end ? convertToDateTimeLocal(event.end) : "",
    title: event?.title || "",
    body: event?.body || "",
    calendar: calendar,
    isCompleted: false,
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Reset form
    await OpenPlanner.addEvent({
      ...formData,
      start: new Date(formData.start).toISOString(),
      end: new Date(formData.end).toISOString(),
    });
    setFormData({
      start: "",
      end: "",
      title: "",
      body: "",
      calendar: "",
    });

    onSubmit();
  };

  const handleTitle = (e) => {
    setFormData((prevData) => ({
      ...prevData,
      title: e.target.value,
    }));
  };
  const handleBody = (e) => {
    setFormData((prevData) => ({
      ...prevData,
      body: e.target.value,
    }));
  };

  return (
    <Box sx={{ padding: 3, maxWidth: 600, margin: "0 auto" }}>
      <Typography variant="h4" gutterBottom>
        Add Event
      </Typography>
      <form onSubmit={handleSubmit}>
        <TextField
          label="Start"
          name="start"
          type="datetime-local"
          value={formData.start}
          onChange={handleChange}
          fullWidth
          margin="normal"
          required
        />
        <TextField
          label="End"
          name="end"
          type="datetime-local"
          value={formData.end}
          onChange={handleChange}
          fullWidth
          margin="normal"
          required
        />
        Title
        <EditorProvider>
          <Editor value={formData.body} onChange={handleBody}></Editor>
        </EditorProvider>
        Optional Notes
        <EditorProvider>
          <Editor
            containerProps={{
              style: {
                minHeight: "20px",
              },
            }}
            value={formData.title}
            onChange={handleTitle}
          ></Editor>
        </EditorProvider>
        {courses && (
          <FormControl fullWidth margin="normal">
            <InputLabel id="calendar-label">Calendar</InputLabel>
            <Select
              labelId="calendar-label"
              name="calendar"
              value={formData.calendar}
              onChange={handleChange}
              required
            >
              {courses.map((calendar) => (
                <MenuItem key={calendar.id} value={calendar.id}>
                  {calendar.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
        <Button
          type="submit"
          variant="contained"
          color="primary"
          sx={{ mt: 2 }}
        >
          Save
        </Button>{" "}
        <Button sx={{ mt: 2 }} onClick={onSubmit}>
          Cancel
        </Button>
      </form>
    </Box>
  );
}
